@keyframes fadeIn {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: all;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    pointer-events: all;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 11111;
  display: flex;
  overflow-y: scroll;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms linear, pointer-events 0s linear 250ms; 
}

.backdrop.open {
  animation: fadeIn 250ms forwards;
}

.backdrop.closed {
  animation: fadeOut 250ms forwards;
}

.modal {
  position: relative;
  max-width: 666px;
  width: 100%;
  margin: auto;
  border-radius: 30px;
  background: var(--background);
  box-shadow: 0px 4px 20px 0px rgba(51, 48, 228, 0.5);
}

@media screen and (max-width: 768px) {
  .modal {
    max-width: 450px;
  }
}

@media screen and (max-width: 500px) {
  .modal {
    max-width: 280px;
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;

  cursor: pointer;
}

.modalHeader {
  background: #3330e4;
  padding: 25px 0;
  border-radius: 30px 30px 0px 0px;
}

.modalHeader {
  color: #fff;
  text-align: center;
  font-family: Geometria;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}

@media screen and (max-width: 550px) {
  .modalHeader {
    font-size: 18px;
  }
}
