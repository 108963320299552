.add-influencer-modal {
    padding: 40px 60px;

    .search-container {
        margin: 0 auto;
        width: 100%;
    }
}

.result-block {
    margin-top: 20px;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.result-block.visible {
    opacity: 1;
    transform: translateY(0);
    
    #acc-info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo-username {
            display: flex;
            align-items: center;

            #sm-logo {
                display: flex;

                #sm {
                    width: 35px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                #logo {
                    width: 70px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }

            p {
                font-family: Geometria;
                font-size: 20px;
                font-weight: 700;
                text-align: left;
            }
        }

        .price-followers {
            p {
                font-family: Geometria;
                font-size: 20px;
                font-weight: 700;
                text-align: left;
            }

            span {
                font-family: Geometria;
                font-size: 18px;
                font-weight: 500;
                text-align: left;
            }
        }
    }
    
    .add-influencer-button {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}