.genre-buttons-list-container {
    #message {
        margin-top: 20px;
        font-family: Geometria;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        align-items: center;
    }
}

.genre-buttons-list {
    display: flex;
    justify-content: center;
    margin: 40px 20px 0 20px;
    white-space: nowrap;
}

@media screen and (max-width: 1300px) {
    .genre-buttons-list {
        overflow-x: auto;
        margin: 10px 0 20px 0;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 550px) {
    .genre-buttons-list {
        margin-top: 20px;
        padding-left: 10px; 
    }
}
