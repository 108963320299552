.report {
  &-po-form {
    width: 50%;
    margin: 0 auto;

    @media screen and (max-width: 900px) {
      width: 100%;

      &-title {
        display: flex;
        justify-content: center;
      }
    }
  }

  &-campaign-strategy-share {
    @media (max-width: 900px) {
      margin-top: 10px;
    }
  }

  &-campaign-strategy-title {
    @media screen and (max-width: 900px) {
      margin-top: 30px !important;
      display: flex;
      flex-direction: column;
    }
  }

  &-details {
    width: 90%;
    padding: 0 69px;
    margin: 60px auto;
    display: flex;
    justify-content: space-around;

    p {
      font-family: Geometria;
      font-size: 24px;
      font-weight: 400;
    }

    span {
      font-family: Geometria;
      font-size: 24px;
      font-weight: 700;
    }

    &-first,
    &-third {
      flex: 1;
      border: 2px solid #3330E4;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px 0 20px 64px;
    }

    &-second {
      flex: 1;
      border-top: 2px solid #3330E4;
      border-bottom: 2px solid #3330E4;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px 0 20px 64px;
    }

    &-first {
      border-radius: 40px 0 0 40px;
    }

    &-third {
      border-radius: 0 40px 40px 0;
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
      //margin: 20px auto 0;
      width: 90%;
      padding: 0;

      p {
        font-size: 20px;
      }

      span {
        font-size: 20px;
      }

      &-first, &-second, &-third {
        padding: 20px 0 20px 20px;
      }

      &-first {
        border-radius: 40px 40px 0 0;
      }

      &-second {
        border-top: none;
        border-bottom: none;
        border-left: 2px solid #3330E4;
        border-right: 2px solid #3330E4;
      }

      &-third {
        border-radius: 0 0 40px 40px;
      }
    }
  }
}

.report-campaign-name {
  font-family: Geometria;
  font-size: 24px;
  font-weight: 700;
  margin: 0 auto;
  text-align: center;
  cursor: default;
}

.report-info {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  &-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-text {
    color: var(--text);
    font-family: Geometria;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &-span {
      font-weight: 700;
    }

    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
}

.report-table {
  width: 90%;
  min-width: 90%;
  margin: 0 auto 40px;
  border-collapse: collapse;
  box-shadow: 0px 4px 20px 0px #3330E480;
  border-radius: 30px;
  overflow: hidden;

  .report-table-header {
    font-family: Geometria;
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    background-color: blue;
    color: white;
    height: 84px;
    border: 1px solid blue;
    wrap-option: wrap;
    word-break: break-word;
  }

  .report-table-body {
    background-color: white;

    td {
      font-family: Geometria;
      font-size: 15px;
      font-weight: 400;
      border: 1px solid #c8c4f4;

      &:last-child {
        border-right: none;
      }
    }

    #username {
      min-width: 206px;
      max-width: 206px;
      padding: 9px 0 9px 7px;
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      background-color: #f0ecfc;

      #username-container {
        display: flex;
        gap: 10px;

        img {
          width: 15px;
        }
      }

      @media (max-width: 1650px) {
        width: 150px !important;
        min-width: 150px !important;
      }

      @media (max-width: 1550px) {
        padding-left: 10px;
      }
    }

    #followers {
      width: 108px;
      font-family: Geometria;
      font-size: 16px;
      text-align: center;
      background-color: #f0ecfc;

      @media (max-width: 1550px) {
        width: 85px;
      }

      @media (max-width: 1250px) {
        font-size: 14px;
      }
    }

    #date {
      max-width: 200px !important;
      font-family: Geometria;
      font-size: 15px;
      font-weight: 400;
      text-align: left;
      padding: 9px 0 9px 20px;

      @media (max-width: 1650px) {
        width: 100px !important;
        padding-left: 10px;
      }
    }

    #videolink {
      padding: 5px 3px;
      border-bottom: 1px solid #c8c4f4;

      .videolink-container {
        margin: 0 auto;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        min-width: 45px;
        max-width: 55px;
        height: 28px;
        border-radius: 10px;
        border: 1px solid black;
        padding: 0 3px;
        transition: box-shadow 0.3s ease;

        p {
          font-family: Geometria;
          font-size: 15px;
          font-weight: 500;
          margin-left: 5px;
          margin-bottom: 1px;
        }

        img {
          width: 18px;
        }

        &:hover {
          box-shadow: 0 0 7px 0 black;
        }
      }
    }

    #post-decription {
      min-width: 300px !important;
      width: 23%;
      padding: 4px 0 4px 17px;

      div {
        font-family: Geometria;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        text-indent: 10px;
        max-height: 40px !important;
        overflow-y: auto !important;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: #D6D6FA;
          border-radius: 4px;
          transition: background 0.3s ease;
        }

        &:hover::-webkit-scrollbar-thumb {
          background: rgba(50, 47, 228, 0.7);
        }

        &::-webkit-scrollbar-track {
          background: #EBEAFC;
          border-radius: 4px;
        }
      }

      @media (max-width: 1650px) {
        width: 250px !important;
        min-width: 250px !important;
      }

      @media (max-width: 1550px) {
        padding-left: 10px;
      }

      @media (max-width: 1400px) {
        width: 150px !important;
        min-width: 150px !important;
      }
    }

    #story-tag {
      width: 150px;
      padding: 4px 0 4px 17px;

      div {
        font-family: Geometria;
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        max-height: 40px !important;
        overflow-y: auto !important;
        line-height: normal;
        display: flex;
        align-items: center;

        &::-webkit-scrollbar {
          width: 4px;
          height: 2px;
        }

        &::-webkit-scrollbar-thumb {
          background: #D6D6FA;
          border-radius: 4px;
          transition: background 0.3s ease;
        }

        &:hover::-webkit-scrollbar-thumb {
          background: rgba(50, 47, 228, 0.7);
        }

        &::-webkit-scrollbar-track {
          background: #EBEAFC;
          border-radius: 4px;
        }
      }

      @media (max-width: 1550px) {
        padding-left: 10px;
      }
    }

    #story-link {
      cursor: pointer;
      max-width: 150px !important;
      min-width: 80px;
      font-family: Geometria;
      font-size: 15px;
      font-weight: 400;
      padding: 0 5px 2px 5px;
      text-align: left;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: blue;
      overflow-x: auto;

      &::-webkit-scrollbar {
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #D6D6FA;
        border-radius: 4px;
        transition: background 0.3s ease;
      }

      &:hover::-webkit-scrollbar-thumb {
        background: rgba(50, 47, 228, 0.7);
      }

      &::-webkit-scrollbar-track {
        background: #EBEAFC;
        border-radius: 4px;
      }

      @media (max-width: 1650px) {
        width: 100px !important;
        max-width: 100px !important;
      }

      @media (max-width: 1450px) {
        width: 70px !important;
        max-width: 70px !important;
      }
    }

    #post-link {
      min-width: 50px;
      padding: 5px 3px;
      border-bottom: 1px solid #c8c4f4;

      .post-link-container {
        margin: 0 auto;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 28px;
        gap: 8px;
        border-radius: 10px;
        border: 1px solid black;
        padding: 0 5px;
        transition: box-shadow 0.3s ease;

        img {
          width: 18px;
        }

        &:hover {
          box-shadow: 0 0 7px 0 black;
        }
      }
    }

    #impressions {
      width: 108px;
      font-family: Geometria;
      font-size: 16px;
      text-align: center;

      @media (max-width: 1550px) {
        width: 85px;
      }
    }
  }

  .report-table-footer {
    border-top: 1px solid #c28f80;

    td {
      height: 72px;
      font-family: Geometria;
      font-size: 16px;
      font-weight: 700;
      text-align: center;

      &:first-child {
        border-left: 1px solid #ff7c0c;
      }

      &:last-child {
        border-right: 1px solid #ffbc84;
      }
    }

    td:first-child {
      background: #ff7c0c;
      font-family: Geometria;
      font-size: 18px;
      font-weight: 800;
      text-align: center;
      //padding-left: 43px;
    }

    td:nth-child(2n+3) {
      background-color: #ffbc84;
    }

    td:nth-child(2n+2):not(:first-child) {
      background-color: #f0ac84;
    }
  }
}


.report-mobile-table {
  width: 90%;
  margin: 40px auto;
  border-collapse: collapse;
  box-shadow: 0px 4px 20px 0px #3330E480;
  border-radius: 30px;
  overflow: hidden;

  .account-item {
    .header {
      font-family: Geometria;
      font-size: 14px;
      font-weight: 700;
      background: #3330E4;
      color: white;
      height: 49px;
      align-items: center;
      display: flex;
      justify-content: center;

      @media (min-width: 900px) and (max-width: 1150px) {
        height: 65px;
        font-size: 16px;
      }
    }

    .body {
      display: flex;

      .account-info {
        display: flex;
        flex-direction: column;
        background-color: #c8c4f4;
        //padding: 13px 5px 9px 11px;
        gap: 10px;
        width: 35%;
        max-width: 35%;

        p {
          margin-left: 11px;
          font-family: Geometria;
          font-size: 10px;
          font-weight: 400;
          color: #808080;
        }

        span {
          margin-left: 11px;
          font-family: Geometria;
          font-size: 14px;
          font-weight: 400;
        }

        #genres {
          span {
            display: block;
          }
        }

        .footer-left-side {
          background: #ff7c0c;
          border-radius: 0 0 0 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 72px;
          margin-top: auto;

          p {
            margin: 0;
            text-align: center;
            color: black;
            max-width: 46px;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }

      .campaign-info {
        max-width: 65% !important;
        width: 65%;
        background-color: white;
        gap: 10px;
        display: flex;
        flex-direction: column;

        p {
          margin-left: 19px;
          font-family: Geometria;
          font-size: 10px;
          font-weight: 400;
          color: #808080;
        }

        span {
          margin-left: 19px;
          font-family: Geometria;
          font-size: 15px;
          font-weight: 400;
        }

        #videoLink {
          #img {
            margin-left: 19px;
            cursor: pointer;
            margin-top: 3px;
            border: 1.5px solid #000000;
            width: 49px;
            height: 23px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            padding: 0 3px;

            span {
              margin: 0;
              font-weight: 500;
            }
          }

          #img:hover {
            box-shadow: 0 0 7px 0 black;
            transition: box-shadow 0.2s ease;
          }
        }

        #post-description {
          div {
            padding-left: 19px;
            max-height: 58px;
            overflow-y: hidden;
            overflow-x: hidden;
            transition: max-height 0.5s ease;

            span {
              margin: 0;
            }
          }

          button {
            font-family: Geometria;
            font-size: 10px;
            font-weight: 400;
            color: grey;
            text-decoration: underline;
            text-decoration-style: solid;
            text-underline-position: from-font;
            text-align: center;
            cursor: pointer;
            width: 100%;
          }
        }

        #story-link {
          div {
            margin-left: 19px;
            width: 90%;
            overflow-x: auto;
            overflow-y: hidden;

            span {
              margin-left: 0;
              color: blue;
              text-decoration: underline;
              text-decoration-style: solid;
              text-underline-position: from-font;
            }

            &::-webkit-scrollbar {
              height: 6px;
            }

            &::-webkit-scrollbar-thumb {
              background: #D6D6FA;
              border-radius: 4px;
              transition: background 0.3s ease;
            }

            &:hover::-webkit-scrollbar-thumb {
              background: rgba(50, 47, 228, 0.7);
            }

            &::-webkit-scrollbar-track {
              background: #EBEAFC;
              border-radius: 4px;
            }
          }
        }

        .footer-right-side {
          background: #ffbc84;
          border-radius: 0 0 30px 0;
          height: 72px;
          align-items: center;
          display: flex;
          margin-top: auto;

          p {
            font-size: 14px;
            font-weight: 700;
            color: black;
            max-width: 46px;
            margin: 0 0 0 19px;
          }
        }
      }

      @media (min-width: 900px) and (max-width: 1150px) {
        .account-info {
          width: 25%;
          max-width: 25%;

          p {
            font-size: 13px;
          }

          span {
            font-size: 16px;
          }
        }

        .campaign-info {
          max-width: 75% !important;
          width: 75%;

          p {
            font-size: 13px;
          }

          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.features-btns {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  #csv-download {
    cursor: pointer;
    width: 55px;
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    p {
      font-family: Geometria;
      font-size: 12px;
      font-weight: 500;
    }

    img {
      width: 35px;
      height: 35px;
      rotate: 90deg;
    }
  }

  #share-link {
    cursor: pointer;
    width: 55px;
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    p {
      font-family: Geometria;
      font-size: 12px;
      font-weight: 500;
    }

    img {
      width: 35px;
      height: 35px;
    }
  }
}